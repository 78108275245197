import React from 'react'
import './FirstSection.css'

import Logo from './components/Logo'
import Qmenu from './components/Qmenu'
import Navbar from './components/Navbar'

function firstSection() {
    return (
        <div className="firstSection">
            <Logo defaultUrl="images/Matfix_logo_white.svg" changedUrl="images/Creative_logo.svg" />
                <h1 className="headline">kreativní <br /> tvorba </h1>
                <img id="img_otisk" alt="/" src='images/Branding_icon.svg'/>
                <a href="#SS">
                    <img id="corner" alt="/" src="images/Corner_1.svg"/>
                </a>
                <div className="Qmenu_center">
                    <Qmenu adr="/services/logo-identita" name={"logo & identita"} link="images/Logo_icon.svg" />
                    <Qmenu id={"modif"} name={"web & online"} adr="/services/web-online" link="images/web_icon.svg" />
                    <Qmenu name={"tisk & výroba"} adr="/services/tisk-vyroba" link="images/Vyroba_icon.svg" />
                </div>
            <Navbar imgSRC="/images/Menu_icon_white.svg" />
        </div>
    )
}

export default firstSection
