import React from 'react'
import './SluzbyComp.css'
import { SecondSectionData } from '../data/SecondSectionData'
import { Link } from 'react-router-dom'

//import Fade from 'react-reveal/Bounce'

function SluzbyComp() {
    return (
        <div className="SC_grid">
            {SecondSectionData.map((item, index) => {
                return (
                    <Link to={item.link}>
                        <div className="SC_mainComp" key={index}>
                            <div className="SC_img">
                                <img src={item.image} alt="/" />
                                <h3>{item.title1} <br/> {item.title2}</h3>
                            </div>
                            <img className="SC_corner" src="images/Corner_1.svg" alt="/"/>
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}

export default SluzbyComp

/*
    <div className="SC_grid">
        {SecondSectionData.map((item, index) => {
            return (
                <Link to={item.link}>
                <div className="SC_mainComp" key={index}>
                    <div className="SC_img">
                        <img src={item.image} alt="/" />
                        <h3>{item.title1} <br/> {item.title2}</h3>
                    </div>
                    <img className="SC_corner" src="images/Corner_1.svg" alt="/"/>
                </div>
                </Link>
            )
        })}
    </div>

----------------------------------------------------------------------------------------
OPRAVIT ANIMACE

        <div className="SC_grid">
            <Fade top delay={200}>
                <div className="SC_mainComp">
                    <div className="SC_img">
                        <img src="/images/Vyroba_icon.svg" alt="/" />
                        <h3>logo <br/> identita</h3>
                    </div>
                    <img className="SC_corner" src="images/Corner_1.svg" alt="/"/>
                </div>
            </Fade>
            <Fade top delay={800}>
                <div className="SC_mainComp">
                    <div className="SC_img">
                        <img src="/images/Vyroba_icon.svg" alt="/" />
                        <h3>logo <br/> identita</h3>
                    </div>
                    <img className="SC_corner" src="images/Corner_1.svg" alt="/"/>
                </div>
            </Fade>
            <Fade top delay={4000}>
                <div className="SC_mainComp">
                    <div className="SC_img">
                        <img src="/images/Vyroba_icon.svg" alt="/" />
                        <h3>logo <br/> identita</h3>
                    </div>
                    <img className="SC_corner" src="images/Corner_1.svg" alt="/"/>
                </div>
            </Fade>
        </div>
 */