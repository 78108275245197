import React from 'react'
import './PraceComp.css'

import Fade from 'react-reveal/Fade'

class PraceComp extends React.Component {

    render() {
        return (
            <div className="PC_main1">
                <Fade top delay={200}>
                        <div className="PC_comp1" >
                            <div>
                                <h1>1</h1>
                                <h2>kontakt</h2>
                            </div>
                            <p>Zavolejte nebo napište. Zadání projdeme, vyjasníme si konkrétní cíle projektu a navrhneme efektivní řešení.</p>
                        </div>
                </Fade>
                <Fade top delay={1200}>
                        <div className="PC_comp1" >
                            <h1>2</h1>
                            <h2>kalkulace</h2>
                            <p>Po odsouhlasení zadání a rozsahu projektu připravíme přehlednou kalkulaci a stanovíme orientační termín tvorby.</p>
                        </div>
                </Fade>
                <Fade top delay={2200}>
                        <div className="PC_comp1" >
                            <h1>3</h1>
                            <h2>průzkum</h2>
                            <p>Jdeme na to. Průzkum daného odvětví a konkurence. Ujasnění směru a stylu, kterým se odlišíte a budete výjimeční.</p>
                        </div>
                </Fade>
                <Fade top delay={3200}>
                        <div className="PC_comp1" >
                            <h1>4</h1>
                            <h2>tvorba</h2>
                            <p>Čas vzít tužku do ruky a nechat myšlenky plynout.. Skicování, utřídění nápadů, vizuální ztvárnění. Nejzábavnější část.</p>
                        </div>
                </Fade>
                <Fade top delay={4200}>
                        <div className="PC_comp1" >
                            <h1>5</h1>
                            <h2>finalizace</h2>
                            <p>Představení návrhu, můžou nastat finální úpravy a poté je vše připraveno k výrobě, tisku a spuštění celého projektu. </p>
                        </div>
                </Fade>
            </div>
        )
    }
}

export default PraceComp
