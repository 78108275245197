import React from 'react'
import './SecondSection.css'
import SluzbyComp from './components/SluzbyComp'

function SecondSection() {
    return (
        <div className="secondSection">
            <SluzbyComp />
        </div>
    )
}

export default SecondSection
