/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Link, BrowserRouter as Router, Route } from 'react-router-dom'
import ServicesNav from '../components/ServicesNav'

import './Services.css'

import S_Logo from '../components/_services/S_Logo'
import S_Web from '../components/_services/S_Web'
import S_Tisk from '../components/_services/S_Tisk'
import S_Design from '../components/_services/S_Design'
import S_Prezentace from '../components/_services/S_Prezentace'
import S_Reklama from '../components/_services/S_Reklama'
import S_MainPhoto from '../components/_services/S_MainPhoto'
import Logo from '../components/Logo'

import Fade from 'react-reveal/Fade'
import S_Textbar from '../components/_services/S_Textbar'

import NavbarVid from '../components/NavBarProvizorni'

class Services extends React.Component {
    render() {
        return (
            <>
            <Logo defaultUrl="/images/Matfix_logo_white.svg" changedUrl="/images/Creative_logo.svg" />
            <NavbarVid imgSRC="/images/Menu_icon_white.svg" />
            <img id="S_Corner" alt="/" src="/images/Corner_1.svg" />
            <Router>
                <ServicesNav />
                <Route path='/services/logo-identita'><S_MainPhoto kategorie='logo'/></Route>
                <Route path='/services/web-online'><S_MainPhoto kategorie='web'/></Route>
                <Route path='/services/tisk-vyroba'><S_MainPhoto kategorie='tisk'/></Route>
                <Route path='/services/graficky-design'><S_MainPhoto kategorie='grafika'/></Route>
                <Route path='/services/prezentacni-systemy'><S_MainPhoto kategorie='prezentacni-systemy'/></Route>
                <Route path='/services/reklamni-polepy'><S_MainPhoto kategorie='polepy-aut'/></Route>
                <div className="Services_info">
                    <Fade top>
                        <Route path='/services/logo-identita'>
                            <S_Textbar 
                            nadpis1="tvorba loga" 
                            text1="Tvorba loga začíná důkladným rozborem dané problematiky, utříděním myšlenek, skicováním nápadů a vytvořením jedinečného funkčního loga." 
                            nadpis2="tvorba identity" 
                            text2="Firemní identita musí správně a jednotně komunikovat s cílovou skupinou. Pomůže k vybudování pevného vztahu se zákazníkem a udržení povědomí." 
                            nadpis3="redesign" 
                            text3="Zachování hodnot a historie firmy je důležitou součástí k vytvoření správného firemního redesignu, který dá nový impuls a sjednotí vizuální styl firmy." 
                            />
                        </Route>
                    </Fade>
                    <Fade top>
                        <Route path='/services/web-online'>
                            <S_Textbar 
                            nadpis1="tvorba webu" 
                            text1="Začíná důkladným zadáním, průzkumem trhu a stanovením cílové skupiny. Poté přijde na řadu prototypování a na konci tvorba webu s atraktivním designem, který vychází z firemní identity. " 
                            nadpis2="tvorba aplikací" 
                            text2="Firemních i veřejných mobilních aplikací, které jsou dnes běžnou součástí života, zjednodušují procesy a šetří čas." 
                            nadpis3="online marketing" 
                            text3="V online světě prostě být vidět musíte. Proto je reklama ve vyhledávačích a na sociálních sítích nedílnou součástí webových služeb." 
                            />
                        </Route>
                    </Fade>
                    <Fade top>
                        <Route path='/services/tisk-vyroba'>
                            <S_Textbar 
                            nadpis1="tisk a výroba" 
                            text1="Co se u nás navrhne, to také dodáme a zrealizujeme. Tisk firemních tiskovin, velkoformátový tisk, vícenákladový tisk. Realizace a polepy aut / výloh." 
                            nadpis2="potisk textilu" 
                            text2="Potisk a výšivka textilu s firemní nebo klubovou grafikou umístěna na kvalitním a reprezentativním textilu dělá radost a reklamu zároveň." 
                            nadpis3="reklamní předměty" 
                            text3="K budování povědomí o firemně pomůže také vlastní reklamní předmět, který může být praktický i stylový zároveň. " 
                            />
                        </Route>
                    </Fade>
                    <Fade top>
                        <Route path='/services/graficky-design'>
                            <S_Textbar 
                            nadpis1="tvorba designu" 
                            text1="Grafický design všech tiskových i online materiálu. Příprava veškerých formátů, které doprovází již zaběhlý vizuální styl firmy." 
                            nadpis2="tvorba katalogů" 
                            text2="Kvalitně zpracované katalogy, které zapadají do vizuálního stylu firmy, jsou důležité pro prezentaci aktuální firemní nabídky." 
                            nadpis3="obalový design" 
                            text3="Obal prodává a v mnoha případech rozhoduje o prodeji. Tvorba obalů má svá pravidla, která je potřeba znát a počítat s nimi při zpracování." 
                            />
                        </Route>
                    </Fade>
                    <Fade top>
                        <Route path='/services/prezentacni-systemy'>
                            <S_Textbar 
                            nadpis1="rollup systémy a vlajky" 
                            text1="Roll-upové a vlajkové systémy jsou skvělým prezentačním systémem, které pomáhají rychle a efektivně sdělovat důležité informace." 
                            nadpis2="prezentační pulty a stěny" 
                            text2="Zaujmout na první pohled a vyjímat se na veletrhu je cíl. Proto je nejen důležité grafické zpracování, ale i kvalita a originalita prezentačních systémů." 
                            nadpis3="reklamní nůžkové stany" 
                            text3="Mít svůj vlastní kvalitní nůžkový stan je nejen profesionální, ale velice praktické pro veškeré firemní akce a prezentace." 
                            />
                        </Route>
                    </Fade>
                    <Fade top>
                        <Route path='/services/reklamni-polepy'>
                            <S_Textbar 
                            nadpis1="polepy aut" 
                            text1="Polepy a celopolepy pomohou ke snadnému zviditelnění a zvýšení povědomí o firmě. Jedno z nejefektivnějších řešení jak investovat do reklamy." 
                            nadpis2="výlohy a poutače" 
                            text2="Je potřeba být vidět. A to především tam, kde se firma nachází. Realizace a polep reklamních cedulí, výloh a poutačů." 
                            nadpis3="firemní značení" 
                            text3="Interiérové a exteriérové firemní značení srozumitelně naviguje zákazníka tam, kam je potřeba." 
                            />
                        </Route>
                    </Fade>
                </div>
                    <Route path='/services/logo-identita' component={S_Logo} />
                    <Route path='/services/web-online' component={S_Web} />
                    <Route path='/services/tisk-vyroba' component={S_Tisk} />
                    <Route path='/services/graficky-design' component={S_Design} />
                    <Route path='/services/prezentacni-systemy' component={S_Prezentace} />
                    <Route path='/services/reklamni-polepy' component={S_Reklama} />

                </Router>

                {/* Link by měl být u textu, problém je že ten se nechází v jiném routeru než kam patří tenhle. 
                  * Rouing potom správně nefunguje. Nechat ho mimo nelze protože velikost stránky se mění a button se posouvá s ní.
                */} 
                <Link to="/kontakt" className="S_btn_animate S_btn_link1">
                    <h1 className="S_btn_headline">kontakt</h1>
                    <img src="/images/Corner_1.svg" alt="/" />
                </Link>
                
                <div className="S_Footer">
                    <Link to="/kontakt" className="S_btn_animate S_btn_link2">
                        <h1 className="S_btn_headline">kontakt</h1>
                        <img src="/images/Corner_1.svg" alt="/" />
                    </Link>
                </div>
            </>
        )
    }
}

export default Services