import React from 'react'

class S_MainPhoto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        }
    }

    componentDidMount() {
        fetch(`https://api.matfix.cz/api/projects/${this.props.kategorie}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json,
                })
            });
    }

    render() {

        var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <img className='Services_main_photo' alt="" src='/images/White.png' />
        } 
        else {
            return (
            <>
                <img className='Services_main_photo' alt="img" src={items[0].imageUrl} />
            </>
        )}
    }
}

export default S_MainPhoto

/*
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        }
    }

    componentDidMount() {
        fetch(`https://api.matfix.cz/api/projects/${this.props.kategorie}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json,
                })
            });
    }

    render() {

        var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <img className='Services_main_photo' alt="" src='/images/White.png' />
        } 
        else {
            return (
            <>
                <img className='Services_main_photo' alt="img" src={items[0].imageUrl} />
            </>
        )}
    }
}
*/
/* ROZPRACOVANÉ - uložit data z api do pole a pak mezi nimi pouze vybírat
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            category: [['logo', ''], ['web', ''], ['tisk', ''], ['grafika', ''], ['prezentacni-systemy', ''], ['polepy-aut', '']],
            isLoaded: false,
        }
    }
    
    componentDidMount() {
        var { category, items } = this.state

        for (let i = 0; i < 6; i++) {
            fetch(`https://api.matfix.cz/api/projects/${category[i]}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json,
                })
            });
        }
        
    }

    render() {

        var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <img className='Services_main_photo' alt="" src='/images/White.png' />
        } 
        else {
            return (
            <>

            </>
        )}
    }
*/