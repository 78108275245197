import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../components/Logo'
import NavbarVid from '../components/NavBarProvizorni'

import './404.css'

function Err404() {
    return (
        <div className="Err-container">
            <NavbarVid imgSRC="/images/Menu_icon_white.svg" />
            <Logo defaultUrl="/images/Matfix_logo_ErrWhite.svg" changedUrl="images/Creative_logo.svg" />
            <h1>ajjj <br/> tady <br/> nic není</h1>
            <img alt='#' className="Err-otaznik" src='/images/Err_otaznik.svg' />
            <Link className="Err-homeButton" to="/">
                <img alt='#' src='/images/Err_corner.svg' />
                <h3 id="Err_btn_headline">na úvod</h3>
            </Link>
        </div>
    )
}

export default Err404
