import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../components/Logo'

import '../pages/Kontakt.css'

import Fade from 'react-reveal/Fade'
import NavbarVid from '../components/NavBarProvizorni'

function Kontakt() {
    return (
        <div className='KontaktPage'>
            <Logo defaultUrl="/images/Matfix_logo_allwhite.svg" changedUrl="images/Creative_logo.svg" />
            <NavbarVid imgSRC="/images/Menu_icon_white.svg" />
            <h1 id="KontaktPage_nadpis">kontakt</h1>
            <Fade top cascade delay={200}>
                <div className='KontaktContainer1'>
                    <h3>Matouš Ingršt</h3>
                    <h3 className="bold">matfix@matfix.cz</h3>
                    <h3>+420 722 915 375</h3>
                </div>
            </Fade>
            <div className='KontaktContainer2'>
                <h3 className="bold">O nás</h3>
                <p>Jsem kreativní designér, který své návrhy realizuje a účastní se i následné výroby. 10 let se věnuji tomu, co mě baví. Jsme kolektiv externích tvůrců, kde se každý věnuje tomu, v čem je silný. Tvoříme menší a rozsáhlé projekty. Naplňuje nás být u vzniku rodinného obchůdku i velkého projektu.</p>
            </div>
            <div className='KontaktContainer3'>
                <h3 className="bold">údaje</h3>
                <p>
                IČ 01223518 (Plátce DPH) <br/>
                Fyzická osoba podnikající dle <br/>
                živ. zákona nezapsaná v obchodním rejstříku.<br/>
                Studio v Pardubicích. <br/>
                </p>
            </div>
            <a href='#'><img alt='#' className="SM_icon" src='/images/Facebook_icon.svg' /></a>
            <a href='http://instagram.com/matfixcreative'><img alt='#' className="SM_icon" id="SM_icon_IG" src='/images/Instagram_icon.svg' /></a>
        </div>
    )
}

export default Kontakt
