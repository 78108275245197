import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

// u odkazu přidat setInterval() - zkusit jakože tě nějdřív hodí link na mainpage a pak tě "a" odkáže na místo na stránce
// animovat změny menu icon na křížek

function NavbarVid(props) {

    const [sidebar, setSideBar] = useState(false)
    const showSidebar = () => setSideBar(!sidebar)

    return (
        <>
            <div className="btnMenu">
                <div>
                <img alt="Menu" src={props.imgSRC} onClick={showSidebar} />
                </div>
            </div>
            
            <nav className={sidebar ? 'sidenav active' : 'sidenav'}>
                <ul className='nav-menu-items' onClick={showSidebar}>
                    <li>
                        <Link to="/" className='navLinks'>
                            úvod
                        </Link>
                    </li>
                    <li>
                        <a href="#SS" className='navLinks'>tvorba</a>
                    </li>
                    <li>
                        <a href="#TS" className='navLinks'>proces</a>
                    </li>
                    <li>
                        <Link to="/kontakt" className='navLinks'>
                            kontakt
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default NavbarVid

/*
const imagesPath = {
    minus: "https://images.vexels.com/media/users/3/131484/isolated/preview/a432fa4062ed3d68771db7c1d65ee885-minus-inside-circle-icon-by-vexels.png",
    plus: "https://cdn3.iconfinder.com/data/icons/glypho-generic-icons/64/plus-big-512.png"
  }

class NavbarVid extends React.Component {

    const [sidebar, setSideBar] = useState(false)
    const showSidebar = () => setSideBar(!sidebar)

    render() {
        <>
            <div className="btnMenu">
                <div>
                <img alt="Menu" src={props.imgSRC} onClick={showSidebar} />
                </div>
            </div>
            <nav className={sidebar ? 'sidenav active' : 'sidenav'}>
                <ul className='nav-menu-items' onClick={showSidebar}>
                    <li>
                        <Link to="/" className='navLinks'>
                            úvod
                        </Link>
                    </li>
                    <li>
                        <a href="#SS" className='navLinks'>tvorba</a>
                    </li>
                    <li>
                        <a href="#TS" className='navLinks'>proces</a>
                    </li>
                    <li>
                        <Link to="/kontakt" className='navLinks'>
                            kontakt
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    }
}
*/