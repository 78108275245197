import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'


function NavbarVid(props) {

    const [sidebar, setSideBar] = useState(false)
    const showSidebar = () => setSideBar(!sidebar)

    return (
        <>
            <div className="btnMenu S_Menu_Local">
                <div>
                <img alt="Menu" src={props.imgSRC} onClick={showSidebar} />
                </div>
            </div>
            <nav className={sidebar ? 'sidenav active' : 'sidenav'}>
                <ul className='nav-menu-items' onClick={showSidebar}>
                    <li>
                        <Link to="/" className='navLinks'>
                            úvod
                        </Link>
                    </li>
                    <li>
                        <Link to="/kontakt" className='navLinks'>
                            kontakt
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default NavbarVid