import React from 'react'
import './Error.css'
import Logo from '../components/Logo'
import NavbarVid from '../components/NavBarProvizorni'

function Error() {
    return (
        <div className="Error">
            <Logo defaultUrl="/images/Matfix_logo_black.svg" changedUrl="images/creative_hover_logo_black.svg" />
            <NavbarVid imgSRC="/images/Menu_icon_black.svg" />
            <h1>Error? I to se může stát, dejte nám vědět a co nejdříve to napravíme.</h1>
            <div className="ErrContainer">
                <form>
                    <label id="email_form">
                        <img alt='foto' src='/images/email_icon.svg'/>
                        <input className="Input_no_outline" placeholder="Váš email" type="text" name="email" />
                    </label>
                </form>
                <textarea placeholder="co se stalo..."/>
            </div>
            <div className="ErrSubmit">
                <h2>odeslat</h2>
                <img src="/images/Arrow_open_black.svg" alt="/" />
            </div>
        </div>
    )
}

export default Error
