import React from 'react'
import './MainPage.css'
import { SectionsContainer, Section } from 'react-fullpage'

import FirstSection from '../FirstSection'
import SecondSection from '../SecondSection'
import ThirdSection from '../ThirdSection'

function MainPage() {
    let options = {
        sectionClassName:     'section',
        anchors:              ['FS', 'SS', 'TS'],
        arrowNavigation:      true,
        scrollBar:            false,
        delay:                1000,
        navigation:           false,
        verticalAlign:        false
      };
    return (
        <div>
        <SectionsContainer className="container" {...options}>
            <Section>
                <FirstSection />
            </Section>
            <Section>
                <SecondSection />
            </Section>
            <Section>
                <ThirdSection />
            </Section>
        </SectionsContainer>
        </div>
    )
}

export default MainPage
