import React from 'react'
import './ThirdSection.css'
import PraceComp from './components/PraceComp'
import PraceCompResponsive from './components/PraceCompResponsive'
import BtnKontakt from './components/Btn_kontakt'

import MediaQuery from 'react-responsive'

function ThirdSection() {
    return (
        <div className='thirdSection'>
            <MediaQuery minWidth={1080}>
                <PraceComp />
            </MediaQuery>
            <MediaQuery maxWidth={1079}>
                <PraceCompResponsive />
            </MediaQuery>
            <BtnKontakt />
        </div>
    )
}

export default ThirdSection
