import React from 'react'
import './S_Logo.css'
import DataFetch from '../DataFetch'

const category = 'web'
/*
function count() {
    let idk
    let i
    while (<DataFetch kategorie={category} id={i} /> !== null) {
        idk = idk + i
        i++
    }
    return idk
}
*/
function S_Web() {

    let numberOfItems=60
    let sequenceLength=3

    let sequenceCount = Math.ceil(numberOfItems/sequenceLength)
    function components() {
        let arr = []
        for (let i = 0; i < sequenceCount; i++) {          
            let iterateCount = sequenceLength

            if (i + 1 === sequenceCount) {
                iterateCount = numberOfItems % sequenceLength

                if(iterateCount===0){iterateCount=sequenceLength}
            }
            for (let j = 0; j < iterateCount; j++) {
                let realIndex = i*3+j+1

                if (j === 0) {
                    arr[realIndex] = (
                    <div className="S_Logo-container right">
                        <DataFetch kategorie={category} id={realIndex} />
                    </div>)
                }
                if (j === 1) {
                    arr[realIndex] =(
                    <div className="S_Logo-container">
                        <DataFetch kategorie={category} id={realIndex} />
                    </div>)
                }
                if (j === 2) {
                    arr[realIndex] =(
                    <div className="S_Logo-container-all">
                        <DataFetch kategorie={category} id={realIndex} />
                    </div>)
                }
                
            }
            
        }
        return arr
    }
    return (
        <div>
            {components()}
        </div>
    )
}

export default S_Web
