import React from 'react'
import { Link } from 'react-router-dom';

class DataFetch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        }
    }

    componentDidMount() {
        fetch(`https://api.matfix.cz/api/projects/${this.props.kategorie}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json,
                })
            });
    }

    render() {

        var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <div></div>
        } 
        else if (items[this.props.id] === undefined) {
            return null
            // Tady by to mělo zahlásit konec
            
        } else {
            return (
                <>
                    <Link to="#">
                        <img alt="img" src={items[this.props.id].imageUrl} />
                    </Link>
                </>
            )
        }
    }
}
export default DataFetch