import React from 'react'
import './App.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import MainPage from './pages/MainPage'
import Kontakt from './pages/Kontakt'
import Error from './pages/Error'
import Services from './pages/Services'
import Err404 from './pages/404'

/** Co ještě nefunguje
 * 
 * 
 * dodělat později
 * menu - odkaz na HP ze side pages
 * čtení dat z api (udělat return z FetchData -> aktuálně generuju 50 elemntů do kterých vkládám protože FetchData nevrací null/undefined -> nelze zjistit kolik je na serveru dat)
 * HP - animace second section
 * Services - top fade effect na text
 * menu - animace z napisu na křížek
 */

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={MainPage} />
        <Route path='/kontakt' component={Kontakt} />
        <Route path='/error' component={Error} />
        <Route path='/services' component={Services} />

        <Route path='*' component={Err404} />
      </Switch>
    </Router>
  )
}

export default App
