
export const SecondSectionData = [
    {
        link: "/services/logo-identita",
        image: "images/Logo_icon.svg",
        title1: "logo & ",
        title2: "identita"
    },
    {
        link: "/services/web-online",
        image: "images/web_icon.svg",
        title1: "web & ",
        title2: "online"
    },    {
        link: "/services/tisk-vyroba",
        image: "images/Vyroba_icon.svg",
        title1: "tisk & ",
        title2: "výroba"
    },    
    {
        link: "/services/graficky-design",
        image: "images/Grafika_icon.svg",
        title1: "grafický",
        title2: "design"
    },    
    {
        link: "/services/prezentacni-systemy",
        image: "images/Systemy_icon.svg",
        title1: "prezentační",
        title2: "systémy"
    },    
    {
        link: "/services/reklamni-polepy",
        image: "images/Polepy_icon.svg",
        title1: "reklamní",
        title2: "polepy"
    },
]