import React from 'react'
import './Btn_kontakt.css'
import { Link } from 'react-router-dom'

function btn_kontakt() {
    return (
        <div className="btnKontakt">
            <Link to="/kontakt" className="btnLink">
                <h1 id="Btn_headline">kontakt</h1>
                <img src="images/Corner_1.svg" alt="/" />
            </Link>
        </div>
    )
}

export default btn_kontakt
