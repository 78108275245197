import React from 'react'
import { Link } from 'react-router-dom'
import './Logo.css'


class Logo extends React.Component {
    render() {
    return (
        <Link to="/" id="logoMainpage">
            <img onMouseOver={e => (e.currentTarget.src = this.props.changedUrl)}
                onMouseOut={e => (e.currentTarget.src = this.props.defaultUrl)}
                alt="logo" 
                src={this.props.defaultUrl}/>
        </Link>
    )
}
}

export default Logo