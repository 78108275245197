import React from 'react'
import { Link } from 'react-router-dom'
import './ServicesNav.css'

import Fade from 'react-reveal/Fade'

//aktivni link je bold

function ServicesNav() {
    return (
        <div id="S-Nav_Container">
            <Fade left delay={300}>
                <div>
                <Link to='/services/logo-identita' className="S-Nav_Links" >logo & <br/> identita</Link>
                </div>
                <Link to='/services/web-online' className="S-Nav_Links" >web & <br/> online</Link>
                <Link to='/services/tisk-vyroba' className="S-Nav_Links" >tisk & <br/> výroba</Link>
                <Link to='/services/graficky-design' className="S-Nav_Links" >grafický <br/> design</Link>
                <Link to='/services/prezentacni-systemy' className="S-Nav_Links" >prezentační <br/> systémy</Link>
                <Link to='/services/reklamni-polepy' className="S-Nav_Links" >reklamní <br/> polepy</Link>
            </Fade>
        </div>
    )
}

export default ServicesNav
