import React from 'react'
import { Link } from 'react-router-dom'
import './Qmenu.css'

function Qmenu(props) {
    return (
        <Link to={props.adr} >
            <div id={props.id} className="Qmenu">
                <img className="img_Qmenu" alt="/" src={props.link}/>
                <h3>{props.name}</h3>
            </div>
        </Link>
    )
}

export default Qmenu
