import React from 'react'
import './PraceCompResponsive.css'

class PraceComp extends React.Component {

    constructor() {
        super()
        this.state = {
            name: "React",
            child1: false,
            child2: false,
            child3: false,
            child4: false,
            child5: false,
        }
        this.hideComponent = this.hideComponent.bind(this)
    }
    // vylepšit syntaxi
    hideComponent(varname) {
        switch (varname) {
            case "child1":
                this.setState({ child1: !this.state.child1, child2: false, child3: false, child4: false, child5: false })
                break;
            case "child2":
                this.setState({ child2: !this.state.child2, child1: false, child3: false, child4: false, child5: false })
                break;  
            case "child3":
                this.setState({ child3: !this.state.child3, child1: false, child2: false, child4: false, child5: false })
                break;  
            case "child4":
                this.setState({ child4: !this.state.child4, child1: false, child2: false, child3: false, child5: false })
            break;  
            case "child5":
                this.setState({ child5: !this.state.child5, child1: false, child2: false, child3: false, child4: false })
            break;       
            default: return;
        }
    }
    render() {
        const { child1, child2, child3, child4, child5 } = this.state
        return (
            <div className="PC_main">
                <div className="PC_comp" >
                    <div onClick={() => this.hideComponent("child1")}>
                        <h1>1</h1>
                        <h2>kontakt</h2>
                    </div>
                    {child1 && <p>Zavolejte nebo napište. Zadání projdeme, vyjasníme si konkrétní cíle projektu a navrhneme efektivní řešení.</p>}
                </div>
                <div className="PC_comp" >
                    <div onClick={() => this.hideComponent("child2")}>
                        <h1>2</h1>
                        <h2>kalkulace</h2>
                    </div>
                    {child2 && <p>Po odsouhlasení zadání a rozsahu projektu připravíme přehlednou kalkulaci a stanovíme orientační termín tvorby.</p>}
                </div>
                <div className="PC_comp" >
                    <div onClick={() => this.hideComponent("child3")}>
                        <h1>3</h1>
                        <h2>průzkum</h2>
                    </div>
                    {child3 && <p>Jdeme na to. Průzkum daného odvětví a konkurence. Ujasnění směru a stylu, kterým se odlišíte a budete výjimeční.</p>}
                </div>
                <div className="PC_comp" >
                    <div onClick={() => this.hideComponent("child4")}>
                        <h1>4</h1>
                        <h2>tvorba</h2>
                    </div>
                    {child4 && <p>Čas vzít tužku do ruky a nechat myšlenky plynout.. Skicování, utřídění nápadů, vizuální ztvárnění. Nejzábavnější část. </p>}
                </div>
                <div className="PC_comp" >
                    <div onClick={() => this.hideComponent("child5")}>
                        <h1>5</h1>
                        <h2>finalizace</h2>
                    </div>
                    {child5 && <p>Představení návrhu, můžou nastat finální úpravy a poté je vše připraveno k výrobě, tisku a spuštění celého projektu. </p>}
                </div>
            </div>
        )
    }
}

export default PraceComp
