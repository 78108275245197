import React from 'react'

function S_Textbar(props) {
    return (
        <div className="Services_info-format">
            <div className="Services_info_item">
                <h3>{props.nadpis1}</h3>
                <p>{props.text1}</p>
            </div>
            <div className="Services_info_item">
                <h3>{props.nadpis2}</h3>
                <p>{props.text2}</p>
            </div>
            <div className="Services_info_item">
                <h3>{props.nadpis3}</h3>
                <p>{props.text3}</p>
            </div>
        </div>
    )
}

export default S_Textbar
